import { Forms } from '@quantumms/cnc-stimulus-utils'

import {I18nEN} from "../translations/en";
import {I18nDE} from "../translations/de";
import {I18n} from "../translations";

export default class extends Forms.ControlsController {
	static targets = ['errorMessage', 'input', 'visibility']

	state = {
		invalidTouched: (context) => {
			return !context.state.valid && context.state.touched;
		}
	}
	classList = {
		invalidInput: {
			className: 'mdc-text-field--invalid',
			state: 'invalidTouched'
		}
	}
	listeners = {
		valid: ['invalidTouched'],
		touched: ['invalidTouched'],
	}

	connected() {
		this.observeValidity();
		this.control.setValue(this.control.reference.value)
		if (this.control.value && this.control.value !== '') {
			this.inputTarget.click()
		}
	}
	observeValidity() {
		let lang = {I18nEN, I18nDE}
		let currentLocale = window.localStorage.locale.toUpperCase();
		let translations = lang['I18n'+currentLocale].validations
		this.control.stateChanged.subscribe(state => {
			if (!state) {
				let firstError = Object.keys(this.control.errors)[0]
				this.errorMessageTarget.innerText = translations[firstError]
			}
		})
	}

	toggleVisibility(event) {
		event.preventDefault();
		let type = this.inputTarget.getAttribute('type') === 'password' ? 'text' : 'password'
		this.inputTarget.setAttribute('type', type)
		this.visibilityTarget.innerHTML = type == 'password' ? 'visibility' : 'visibility_off'
	}
}
