import API from "./api";
import {HTML} from "./api";

export const openSnackbar = function (msg, status) {
    document.getElementById('snack-bar').innerHTML = `
		<div class="d-flex align-items-center justify-content-between py-12 px-20 snack-bar snack-bar--${status} br-4"
		data-controller="toggle">
		  <p class="mr-20">
				${msg}
		  </p>
		  <i class="material-icons-outlined cursor--pointer d-block apple" data-action="click->toggle#toggle">close</i>
		 </div>`;

    setTimeout(function () {
        document.getElementById("snack-bar").innerHTML = "";
    }, 5000);
}

export const call = (path, value, callback, options) => {
    API(path, {
        method: options.verb, body: value
    }).then(response => {
        if (response.ok) {
            response.json().then(responseData => {
                callback(responseData)
            });
        } else {
            errorMessage(response);
        }
    })
}

const errorMessage = function (response) {
    response.json().then(value => {
        if (value.error instanceof Object) {
            window.alert = openSnackbar(value.error.message, 'error')
        } else {
            window.alert = openSnackbar(value.error, 'error')

        }
    })
}


export const setVdom = (api, element = 'html', callback) => {
    let finalApi = api
    finalApi += api.includes('?') ? '&api-call=true' : '?api-call=true'
    HTML(finalApi).then(response => {
        response.text().then(text => {
            let vDom = document.createElement(element)
            vDom.innerHTML = text
            callback(vDom, text)
        })
    })
}