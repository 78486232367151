import { CncController } from "@quantumms/cnc-stimulus-utils";
import {performPermissionToggle, updatePermissionSet} from "../services/permission_set";
import {openSnackbar} from "../services/shared";
import {I18n} from "../translations";

export default class extends CncController {
	update(event) {
		this.setAccessMatrices(performPermissionToggle(event))
	}

	setAccessMatrices(data) {
		let parent = document.getElementById(`${data.permission.uuid}_permission_set`)
		let request = {
			uuid: JSON.parse(parent.dataset.options).permission_set.uuid,
			permission: {
				access_matrices_attributes: [data.permission]
			}
		}
		Object.keys(data.permission.actions).forEach(action => {
			let ele = document.getElementById(`${data.permission.uuid}_permission_${action}`)
			data.permission.actions[action] = ele.checked
		});

		data.permission.disabled = !parent.checked
		this.updateServer(request);
	}

	updateServer(request) {
		updatePermissionSet(request, (value) => {
			openSnackbar(I18n[window.localStorage.locale]['updated_success'], 'success')
		});
	}
}
