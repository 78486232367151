import {Controller} from "stimulus"
import {bulkDeleteUser} from "../services/auth";

export default class extends Controller {
    static targets = ['toggleable'];

    delete(event) {
        let users = [event.currentTarget.dataset.userUuid]

        bulkDeleteUser(users, (response) => {
            if(response) {
                window.location.href = `${window.location.origin}/iam/users`;
            }
        })
    }

}
