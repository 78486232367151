export const I18nDE = {
	sign_in_success: 'Login erfolgreich',
	link_sent_success: 'Link erfolgreich gesendet',
	reset_password_success: 'Passwort erfolgreich zurückgesetzt.',
	set_password_success: 'Passwort erfolgreich gesetzt',
	invalid_token_error: 'Ungültiges Token',
	user_invited: 'User invited successfully',
	user_updated: 'User updated successfully',
	password_updated: 'Password Succesfully Updated',
	user_deleted: 'Users Deleted Successfully',
	Activated: 'Users activated Successfully',
	Deactivated: 'Users deactived Successfully',
	user_remove: 'Users Removed Successfully',
	updated_success: 'Updated Successfully',
	active: 'Aktiv',
	inactive: 'Inaktiv',
	validations: {
		email: 'Bitte gültige E-Mail eingeben',
		required: 'Das ist ein Pflichtfeld',
		identical: 'Passwort muss identisch sein',
		password_mismatch: 'Password needs to be identical'
	}
}
