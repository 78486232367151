import {autoInit} from "material-components-web";
import mdcAutoInit from '@material/auto-init';
import {MDCTextField} from '@material/textfield';
import {Forms} from '@quantumms/cnc-stimulus-utils'
import {invite} from "../services/auth";
import {openSnackbar} from "../services/shared";
import {I18n} from "../translations";


export default class extends Forms.FormsController {
    static targets = ['cancelButton', 'list']

    state = {
        invalid: (context) => {
            return !context.state.valid;
        }
    }

    listeners = {
        valid: ['invalid']
    }

    beforeConnecting() {
        this.registerMDC();
    }

    invite() {
        invite(this.postData, (value) => {
            if (value) {
                if (document.getElementById('add-user')) {
                    this.listTarget.click();
                }
            }
        })
    }

    cancelInvite() {
        let dataset = this.cancelButtonTarget.dataset['idDeletion']
        if (dataset) {
            let elem = document.getElementsByClassName((dataset))[0]
            let counter = elem.childNodes.length
            while (counter) {
                elem.childNodes[counter - 1].remove()
                counter--;
            }
        }
    }

    registerMDC() {
        mdcAutoInit.register('MDCTextField', MDCTextField);
        autoInit();
    }

    get postData() {
        return {
            email: this.form.value.email,
            role_id: this.form.value.role,
            profile_attributes: this.form.value
        }
    }

    get buildReference() {
        return {
            first_name: null,
            salutation: null,
            last_name: null,
            email: null,
            role: null,
            department: null,
            designation: null,
            validators_reference: {
                email: [
                    Forms.QuantumValidators.pattern(/(^\w.*@\w.*\.\w)/, 'email')
                ]
            }
        }
    }
}
