export const I18nEN = {
		sign_in_success: 'Login successfully',
		link_sent_success: 'Link Sent successfully',
		reset_password_success: 'Password Reset successfully',
		set_password_success: 'Password set successfully',
		invalid_token_error: 'Invalid Token',
		user_invited: 'User invited successfully',
		user_updated: 'User updated successfully',
		password_updated: 'Password Succesfully Updated',
		user_deleted: 'Users Deleted Successfully',
		Activated: 'Users activated Successfully',
		Deactivated: 'Users deactived Successfully',
		user_remove: 'Users Removed Successfully',
		updated_success: 'Updated Successfully',
		active: 'Active',
		inactive: 'Inactive',
	validations: {
		email: 'Please enter valid email',
		required: 'Field is required',
		identical: 'Password needs to be identical',
		password_mismatch: 'Password needs to be identical'
	}
}
