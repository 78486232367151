import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['previous', 'next'];

    goNext(event) {
        this.nextTarget.href = window.location.search.replace(
            this.currentPage(this.nextTarget),
            `page=${+this.nextTarget.dataset.currentPage + 1}`
        )
        if (!window.location.search.includes('page')) {
            if(this.nextTarget.href.includes('permission')){
                this.nextTarget.href = window.location.search.replace(
                    '', `?page=${+this.nextTarget.dataset.currentPage + 1}&users=true`
                )
            }
            else{
                this.nextTarget.href = window.location.search.replace(
                    '', `?page=${+this.nextTarget.dataset.currentPage + 1}`
                )
            }
        }
    }

    goPrevious(event) {
        this.previousTarget.href = window.location.search.replace(
            this.currentPage(this.previousTarget),
            `page=${+this.previousTarget.dataset.currentPage - 1}`
        )
    }

    currentPage(target) {
        return 'page=' + target.dataset.currentPage
    }
}
