import {Forms} from '@quantumms/cnc-stimulus-utils'

export default class extends Forms.ControlsController {
    static targets = ['menu', 'roleSelected', 'input', 'icon'];
    static values = {role: String}
    toggled = false

    connected() {
        document.addEventListener("click", (evt) => {
            [this.roleSelectedTarget, this.inputTarget, this.iconTarget].includes(evt.target) ? this.showMenu() : this.removeMenu()
        });
        this.setValue();
    }

    setValue() {
        if (this.control.value) {
            this.element.querySelectorAll('.mdc-list-item').forEach(vo => {
                if (vo.getAttribute('value') === this.control.value) {
                    vo.click()
                }
            })
        }
    }

    showMenu() {
        this.menuTarget.classList.toggle('d-block')
        this.menuTarget.style.opacity = '1';
    }

    removeMenu() {
        this.menuTarget.classList.remove('d-block')
        this.menuTarget.style.opacity = '0';
    }

    injectValue(event) {
        this.roleSelectedTarget.innerHTML = event.target.innerHTML;
        this.control.setValue(event.target.getAttribute('value'))
        this.removeMenu()
    }
}
