import {Controller} from "stimulus";
import mdcAutoInit from "@material/auto-init";
import {MDCTextField} from "@material/textfield";
import {autoInit} from "material-components-web";
import Cropper from  'cropperjs';
import {updateProfilePicture} from "../services/auth";
import {assignUserPermission, getUserPermissionSet} from "../services/permission_set";

export default class extends Controller {
	static targets = ['link']

    connect() {
        document.getElementById('file-input').addEventListener('change', (event) => {
            const image = document.getElementById('profile-picture-image');
            const file = event.target.files?.[0];
            if (file) {
                const modalCheckbox = document.getElementById('profile-cropper-modal');
                if (!modalCheckbox.checked) modalCheckbox.click();

                if (modalCheckbox.checked) {
                    const url = URL.createObjectURL(file);
                    image.src = url;
                    image.onload = () => {
                        this.cropImage(image);
                    };
                }
            }
        });

    }

    registerMDC(){
        mdcAutoInit.register('MDCTextField', MDCTextField);
        autoInit();
    }

    cropImage(image) {
        let croppedData;
        const cropper = new Cropper(image, {
            aspectRatio: 1 / 1,
            zoomable: false,
            viewMode: 1,
            crop(event) {
                croppedData = {
                    width: event.detail.width,
                    height: event.detail.height
                };
            },
            ready() {
                document.getElementById('crop-btn').addEventListener('click', () => {
                    const canvas = cropper.getCroppedCanvas();
                    const image = document.getElementById('profile-picture-image');
                    image.src = canvas.toDataURL();
                    image.onload = () => {
                        cropper.destroy();
                    };
                });
                document.getElementById('rotate-btn').addEventListener('click', () => {
                    cropper.rotate(90);
                });
            },
        });
    }

    saveImage() {
        const modalCheckbox = document.getElementById('profile-cropper-modal');
        if (modalCheckbox.checked) {
            const imgEl = document.getElementById('profile-picture-image');
            const imgUrl = imgEl.src;
            this.loadXHR(imgUrl).then((imgBlob) => {
            		imgBlob.filename = `new_name_secure.${imgBlob.type.split('/')[1]}`
								updateProfilePicture(imgBlob, function (value) {
									const profilePicture = document.getElementById('profile-picture');
									profilePicture.src = imgUrl;
									modalCheckbox.click();
								});
                // upload this imgBlob
                console.log(imgBlob);

                // after uploading
                // document.getElementById('profile-picture').src = 'backend_url';

                // delete when upload and get image URL is implemented
            });
        }
    }

	changePassword() {
		this.openModal('password_change_modal_edition')
	}

	editProfile() {
		this.openModal('auth_modal_edition')
	}

	cancelCropper() {
		document.getElementById('profile-cropper-modal').click()
	}

	getPermissionSets(event) {
		getUserPermissionSet(event.currentTarget.dataset.profileUuid)
	}

	assignPermission(event) {
    let pset = document.querySelector('input[name="permission-set"]:checked').value
		assignUserPermission(pset, event.currentTarget.dataset.currentUser,  (value) => {
			this.linkTarget.href = window.location.href
			this.linkTarget.click();
		});
	}

	openModal(id) {
		const editState = document.getElementById(id)
		if (!editState.checked) {
			editState.click()
		}
		this.registerMDC();
	}

    loadXHR(url) {
        return new Promise(function (resolve, reject) {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.onerror = function () {
                    reject("Network error.")
                };
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        resolve(xhr.response)
                    } else {
                        reject("Loading error:" + xhr.statusText)
                    }
                };
                xhr.send();
            } catch (err) {
                reject(err.message)
            }
        });
    }

    checkedPermission(event) {
	    /*REVISIT-Sonam-Choden-To be done later*/
	/*    let id = event.currentTarget.dataset.id;
	    if(event.currentTarget.checked) {
            document.getElementById('permission-label-'+ id).classList.add('bg--gray-200')
        }*/
	}


}
