import API from "./api";
import {I18n} from '../translations'
import {openSnackbar} from "./shared";

export const performAction = (value, type, callback, options = {}) => {
    switch (type) {
        case    'sign_in':
            signIn(value, callback, options)
            break;
        case    'forgot_password':
            forgotPassword(value, callback, options)
            break;
        case    'reset_password':
            resetPassword(value, callback, options)
            break;
        case 'set_password':
            invitationPassword(value, callback, options)
            break;
    }
}

const signIn = (value, callback, options) => {
    call('/api/v2/iam/users/sign_in', {
        callback_url: options.queries.get('callback_url'),
        omniauth: options.queries.get('omniauth'),
        state: options.queries.get('state'),
        user: value,
    }, function (value) {
        if (value.redirect) {
            window.location.href = value.redirect;
        } else {
            if (options.queries.get('redirect_url')) {
                window.location.href = options.queries.get('redirect_url')
            } else {
                window.alert = openSnackbar(I18n[window.localStorage.locale]['sign_in_success'], 'success')
            }
        }
    }, {verb: 'POST', ...options});
}

const forgotPassword = (value, callback, options) => {
    call('/api/v2/iam/users/password', {
        user: value,
    }, function (value) {
        window.location.href = options.queries.get('callback_url') ? options.queries.get('callback_url') : '/iam/sign_in'
        window.alert = openSnackbar(I18n[window.localStorage.locale]['link_sent_success'], 'success')
    }, {verb: 'POST', ...options});
}

// Check for two workflows 1.invitation or
const resetPassword = (value, callback, options) => {
    if (options.queries.get('token')) {
        call('/api/v2/iam/users/password', {
            user: {
                reset_password_token: options.queries.get('token'),
                ...value
            },
        }, function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale]['reset_password_success'], 'success')
            window.location.href = options.queries.get('callback_url') ? options.queries.get('callback_url') : '/iam/sign_in'
        }, {verb: 'PUT', ...options});
    } else {
        window.alert = openSnackbar(I18n[window.localStorage.locale]['invalid_token_error'], 'error')
    }
}

// Check for two workflows 1.invitation or
const invitationPassword = (value, callback, options) => {
    if (options.queries.get('token')) {
        call('/api/v2/iam/users/invitation', {
            user: {
                invitation_token: options.queries.get('token'),
                ...value
            },
        }, function (value) {
            window.location.href = options.queries.get('callback_url') ? options.queries.get('callback_url') : '/iam/sign_in'
            window.alert = openSnackbar(I18n[window.localStorage.locale]['set_password_success'], 'success')

        }, {verb: 'PUT', ...options});
    } else {
        window.alert = openSnackbar(I18n[window.localStorage.locale]['invalid_token_error'], 'error')
    }
}

export const invite = (value, callback) => {
    call(
        '/api/v2/iam/users/invitation',
        {user: value},
        function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale || 'de']['user_invited'], 'success')
            callback(value);
        },
        {verb: 'POST'})

}


export const updateProfile = (user, data, callback) => {
    call(
        '/iam/edit_profile/' + user,
        {user: {profile_attributes: data}},
        function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale]['user_updated'], 'success')
            callback(value);
        },
        {verb: 'PUT'})
}

export const updateProfilePicture = (image, callback) => {
    let data = new FormData()
    data.append('user[profile_attributes][photo]', image)

    fetch('/api/v2/iam/profiles', {
        method: 'put',
        body: data
    }).then(response => {
        if (response.ok) {
            response.json().then(value => {
                callback(value)
            });
        }
    })
}

const call = (path, value, callback, options) => {
    API(path, {
        method: options.verb, body: {
            tenant: options.queries ? options.queries.get('tenant') : null,
            ...value,
        }
    }).then(response => {
        if (response.ok) {
            response.json().then(value => {
                callback(value)
            });
        } else {
            errorMessage(response);
        }
    })
}

const errorMessage = function (response) {
    response.json().then(value => {
        if (value.error instanceof Object) {
            window.alert = openSnackbar(value.error.message, 'error')
        } else {
            window.alert = openSnackbar(value.error, 'error')

        }
    })
}

export const changePassword = function (data, callback) {
    call(
        '/api/v2/iam/change_password',
        {password: data},
        function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale]['password_updated'], 'success')
            callback(value);
        },
        {verb: 'PUT'})
}

export const bulkDeleteUser = function (data, callback) {
    call(
        '/api/v2/iam/delete_bulk_users',
        {users: data},
        function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale]['user_deleted'], 'success')
            callback(value);
        },
        {verb: 'PUT'})
}

export const activateUser = function (data, id, action, callback) {
    call(
        '/api/v2/iam/users/' + id + '/activate',
        {active: data},
        function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale][action], 'success')
            callback(value);
        },
        {verb: 'PUT'})
}

export const removeFromPermission = function (id, data, callback) {
    call(
        `/api/v2/iam/permissions/${id}/remove_user`,
        {users: data},
        function (value) {
            window.alert = openSnackbar(I18n[window.localStorage.locale]['user_remove'], 'success')
            callback(value);
        },
        {verb: 'PUT'})
};
